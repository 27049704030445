import { jsx as _jsx } from "react/jsx-runtime";
import Exchange from '@shared/ui/display/Exchange';
const RenderExchange = ({ cellParams }) => {
    const exchange = {
        id: '1',
        userId: cellParams.rowData.profile_id,
        type: 'BINANCE',
        properties: {
            apiKey: '',
            secretKey: '',
            connectionName: 'Binance BTC Long',
        },
    };
    return (_jsx(Exchange, { exchange: exchange }));
};
export default RenderExchange;
